<template>
  <Layout
    id="components-layout-demo-custom-trigger"
    :style="{ '--main-color': getDefaultColor }"
    style="flex-direction: row;"
  >
    <div v-if="vis" v-shortkey="['esc']" @shortkey="closeMenu" />
    <KeyboardEvents @keyup="keyboardEvent"></KeyboardEvents>
    <Sider v-model="collapsed" :trigger="null" collapsible>
      <SaveBookingStateButton
        :key="isOpenKey"
        :open="isOpen"
        @closeSave="handleCloseSave"
        style="height: 0; visibility: hidden;"
      />
      <div ref="modalCont" v-shortkey="['esc']" @shortkey="closeConfirmPopup">
        <LeaveModal 
          :visible="confirmPopup" 
          :primaryActionText="$t('leave')"
          :secondaryActionText="$t('save')"
          :text="$t('leave_confirmation')" 
          @closeModal="closeConfirmPopup"
          @onPrimaryClick="handleLeave"
          @onSecondaryClick="openSave"
        />
        <a-modal   
          v-model="updateModal" 
          :getContainer="() => $refs.modalCont" 
          :closable="!isUpdating"
          :maskClosable="!isUpdating"
          :footer="null"
          class="clearance-modal"
        >
          <div class="ticket-stock-info">
            <div
              v-if="!isUpdating && downloadProgress === 0"
              style="display: flex; flex-direction: column;"
            >
              <div class="gradient-top">
                <img 
                  src="../assets/img/logo_liknoss_update.png"
                  alt="update"
                >
                <h2>
                  {{ $t('available_update_title') }}
                </h2>
                <span>
                  {{ $t('available_update_subtitle') }}
                </span>
              </div>
              <br>
              <br>
              <div
                v-if="cdnContent"
                v-html="cdnContent"
                style="max-height: calc(60vh - 200px); padding: 5px; overflow-y: auto;"
              >
              </div>
              <div
                slot="footer"
                style="margin: 10px;"
              >
                <a-button
                  type="button"
                  @click="() => updateModal = false"
                  class="btn-submit ant-btn modal-secondary-action"
                  style="float: right;"
                >
                  {{ $t('cancel') }}
                </a-button>
                <a-button
                  type="button"
                  @click="performUpdate"
                  class="btn-submit modal-default-action"
                  style="float: right; margin-right: 5px;"
                >
                  {{ $t('update') }}
                </a-button>
              </div>
            </div>
            <div
             v-else-if="isUpdating"
            >
              <p>
                {{ $t('manUpdateText') }}
              </p>
              <a-progress :percent="downloadProgress" status="active" />
            </div>
          </div>
        </a-modal>
        
        <a-modal
          v-model="showTicketStatusModal"
          :footer="false"
          :getContainer="() => $refs.modalCont"
          @cancel="() => (showTicketStatusModal = false)"
        >
          <div class="ticket-stock-info">
            <div class="selection-summary">
              {{ $t('ticket_stock') }}
            </div>
            <div
              v-shortkey="['esc']"
              style="
                width: 100%;
                display: flex;
                margin-top: 30px;
                flex-direction: row;
              "
              @shortkey="closeMenu"
            >
              <div
                v-for="(credit, i) in credits && credits.ticketStockInfo"
                :key="i"
                class="cont"
              >
                <span>Series: {{ credit.series }}</span>
                <br>
                <span v-if="credit.automatic === true">
                  Automatic: {{ credit.automatic }}
                </span>
                <span v-if="credit.remaining != null">
                  Remaining: {{ credit.remaining }}
                </span>
              </div>
            </div>
          </div>
        </a-modal>

        <!-- ticket -->
        <a-modal
          v-model="clearancesModal"
          class="clearance-modal"
          :getContainer="() => $refs.modalCont"
          :confirmLoading="loading"
          @ok="downloadClearance"
          @cancel="() => (clearancesModal = false)"
        >
          <div
            v-shortkey="['esc']"
            class="ticket-clearances"
            @shortkey="closeMenu"
          >
            <div class="selection-summary">
              {{ $t('ticket_clearances') }}
            </div>
            <div style="width: 100%; display: flex; margin-top: 30px;">
              <div class="cont">
                <el-date-picker
                  v-model="clearanceYear"
                  type="year"
                  :picker-options="pickerOptions"
                  :placeholder="$t('pick_year')"
                ></el-date-picker>
              </div>
              <div class="cont num">
                <a-input v-model="clearanceNumber" :placeholder="$t('number')" />
              </div>
              <div class="cont">
                <a-select
                  v-model="selectedCompany"
                  filterable
                  :filterOption="filterOption"
                  :placeholder="$t('select_company')"
                  allowClear
                  :notFoundContent="null"
                  showSearch
                  @change="selectCompany"
                >
                  <a-select-option
                    v-for="(c, i) in getcompaniesForSearch"
                    :key="i"
                    :value="c.abbreviation"
                  >
                    {{ c.description }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="cont type">
                <a-select
                  v-model="selectFileType"
                  filterable
                  :filterOption="filterOption"
                  placeholder="Filetype"
                  allowClear
                  :notFoundContent="null"
                  showSearch
                  @change="selectFiletype"
                >
                  <a-select-option
                    v-for="(f, i) in fileTypes"
                    :key="i"
                    :value="f.name"
                  >
                    {{ f.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
        </a-modal>
 
        <!-- financial -->
        <a-modal
          v-model="getFinancialModalStatus"
          class="clearance-modal"
          :destroyOnClose="true"
          :footer="false"
          :getContainer="() => $refs.modalCont"
          @cancel="closeModal"
        >
          <div
            v-shortkey="['esc']"
            class="ticket-clearances"
            @shortkey="closeMenu"
          >
            <h1 
              class="selection-summary" 
              style="font-weight: bold; font-size: 2rem"
            >
              {{ $t('financial_data') }}
            </h1>

            <div style="margin-top: 30px;">

              <div 
                class="financial-header d-flex" 
                style="justify-content: space-evenly; align-items: center;"
              >
                <div class="group-financial">
                  <label for="selectAll">{{ $t("select_all") }}</label>
                  <input type="checkbox" v-model="selectAll" id="selectAll">
                </div>
  
                <a-select
                  v-model="financialCompanies"
                  mode="multiple"
                  placeholder="Please select"
                  style="width: 300px; max-height: 60px; overflow: auto;"
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="(company, i) in filteredCompanies"
                    :key="i"
                  >
                    {{ company.description }} ( {{ company.abbreviation }})
                  </a-select-option>
                </a-select>
  
                <a-button type="primary" @click="handleFinancial" :loading="loadingFinancial">{{ $t("search") }}</a-button>
              </div>

              <br><br>
              
              <div class="financial-results">

                <div class="error" v-if="Object.entries(this.financialDataRes).length === 0 && this.error">
                  <img src="@/assets/img/no-results.png" alt="Error Icon" class="icon" style="width: 100px; filter: opacity(.5);">
                  <span>{{ $t('no_financial_result') }}</span>
                </div>
                
                <div v-else class="d-flex flex-wrap justify-evenly">
                  <div v-for="(data, i) in Object.entries(financialDataRes)" :key="i" class="financial-results--items d-flex flex-column">
                    <h1 class="financial-results--items_title">{{getcompaniesForSearch.find(c => c.abbreviation === data[0]).description}}</h1>
  
                    <div v-for="(string, i) in data[1]" :key="i" class="financial-results--items_data">
                      <img :src="getTypeIcon(string)" alt="Type Icon" class="icon">
                      {{ string }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </a-modal>

        <!-- cancellation fees -->
        <a-modal 
          v-model="visibleCancellationFees" 
          :title="$t('cancellation_fees')" 
          @cancel="() => setModalVisible(false)"
        >
          <div
            v-shortkey="['esc']"
            class="cancellation-fees"
            @shortkey="closeMenu"
          >
            <div style="margin-top: 30px;">
              <div 
                class="financial-header d-flex" 
                style="justify-content: space-evenly; align-items: center; margin-bottom: 5px;"
              >
                <div 
                  class="form-fields-group__in"
                  style="margin-bottom: 0px"
                >
                  <a-input
                     autoComplete="off"
                    :placeholder="$t('ticket_letter')"
                    :maxLength="1"
                    :value="cancellationTicketLetter"
                    @change="(e) => onTicketLetterInputChange('ticketLetter', e.target.value)"
                  />
                </div>
                <div 
                  class="form-fields-group__in"
                  style="margin-bottom: 0px"
                >
                  <a-input
                     autoComplete="off"
                    :placeholder="$t('ticket_number')"
                    :value="cancellationTicketNumber"
                    @change="(e) => onTicketNumberInputChange('ticketNumber', e.target.value)"
                  />
                </div>
                <a-button 
                  type="primary" 
                  @click="handleCancelationFees" 
                  :loading="loadingFinancial">
                  {{ $t("search") }}
                </a-button>
              </div>
              <div class="cancelation-results">
                <div 
                  v-if="this.cancellationResultsHaveError "
                  class="error"
                >
                  <img src="@/assets/img/no-results.png" alt="Error Icon" class="icon" style="width: 100px; filter: opacity(.5);">
                  <span>{{ $t('no_financial_result') }}</span>
                </div>
                <div v-else-if="haveCancellationResult" class="cancellation-results--items flex-column d-flex flex-wrap justify-evenly">
                  <div class="cancellation--items_container d-flex">
                    <p class="cancellation--items_title">{{ $t('companyReservationCode') }}:</p>
                    <p class="cancellation--items_value"> {{ this.cancellationResults.companyReservationCode }}</p>
                  </div>
                  <div class="cancellation--items_container d-flex">
                    <p class="cancellation--items_title">{{ $t('cancellation_fees') }}:</p>
                    <p class="cancellation--items_value"> {{ this.cancellationResults.cancellationFees }}</p>
                  </div>
                  <div class="cancellation--items_container d-flex">
                    <p class="cancellation--items_title">{{ $t('refund_amount') }}:</p>
                    <p class="cancellation--items_value"> {{ this.cancellationResults.refundAmount }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <a-button @click="() => setModalVisible(false)">{{ $t("cancel") }}</a-button>
          </template>
        </a-modal>

      </div>
      
      <div
        id="burger-icon"
        v-shortkey="getFormattedShortcut('Search_By_Reservation')"
        class="burger"
        @click="() => (collapsed = !collapsed)"
        @shortkey="searchByResNumber"
      >
        <span class="line line-one" />
        <span class="line line-two" />
        <span class="line line-three" />
      </div>
      <Menu
        v-shortkey="getFormattedShortcut('Latest_Booking')"
        theme="dark"
        :defaultSelectedKeys="['1']"
        mode="vertical"
        @shortkey="latestBooking"
      >
        <Item key="2">
          <nuxt-link
            :to="localePath('/book-ticket')"
            event=""
            @click.native="resetBooking(false)"
          >
            <img src="@/assets/img/icons/menu/bookaticket.svg">
            <span>{{ $t('menu.book_a_ticket') }}</span>
          </nuxt-link>
        </Item>
        <Item key="7">
          <nuxt-link
            :to="localePath('/booking-search')"
            event=""
            @click.native="resetRecall(false)"
          >
            <img src="@/assets/img/icons/menu/search.svg">
            <span
              v-shortkey="getFormattedShortcut('Search_By_Ticket')"
              @shortkey="searchByTicketNumber"
            >
              {{ $t('menu.search_booking') }}
            </span>
          </nuxt-link>
        </Item>
        <Item key="15">
          <nuxt-link
            :to="localePath('/booking-group-search')"
            event=""
            @click.native="resetGroupRecall(false)"
          >
            <img src="@/assets/img/icons/menu/group.svg">
            <span>{{ $t('menu.search_booking_group') }}</span>
          </nuxt-link>
        </Item>
        <Item 
          v-if="canCheckIn" 
          key="9"
        >
          <nuxt-link :to="localePath('/check-in')">
            <img
              src="@/assets/img/icons/menu/check-in-icon.svg"
              style="max-width: 16px;"
            >
            <span>{{ $t('menu.check_in') }}</span>
          </nuxt-link>
        </Item>
        <a-sub-menu
          v-if="redirectionLinks && redirectionLinks.length"
          key="sub111"
        >
          <span 
            slot="title" 
            class="img-holder"
          >
            <img src="@/assets/img/extra-services.svg">
            <span 
              v-if="!collapsed" 
              class="menu-title"
            >
              {{ $t('menu.extra_services') }}
            </span>
          </span>
          <a-menu-item-group key="g1">
            <a-menu-item 
              v-for="link in redirectionLinks" 
              :key="link.link"
            >
              <a 
                href="javascript:void(0)" 
                @click="redLinks(false, link.link)"
              >
                {{ link.description }}
              </a>
            </a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>
      </Menu>
      <div class="poweredby">
        <div class="liknoss-logo poweredby__logo">
          <div class="liknoss-logo__powered">
            {{ $t('menu.powered') }}
          </div>
          <img 
            class="liknoss-logo__image" 
            src="@/assets/img/Liknoss-Brandmark.png"
          >
        </div>
        <a 
          class="poweredby__terms" 
          target="_blank" 
          rel="noreferrer noopener" 
          href="https://hermescdn.liknoss.com/openseas/disclaimer/english/openseasdiscl.pdf"
        >
          Terms &amp; conditions
        </a>
        <small>{{ $t('version') }}: {{ appVersion }}</small>
      </div>
    </Sider>
    <Layout>
      <Header :class="{ dashboard: $route.path.includes('dashboard') }">
        <div class="headerholder">
          <div class="liknos-logo">
            <img v-if="!isDev" src="@/assets/img/logo_AgencyX.svg" />
            <img v-else src="@/assets/img/logo_AgencyX-christmas.png" />
            <h4 v-if="isDev" style="font-weight: 600; margin: 0; font-size: large;">DEV</h4>
          </div>
          <div class="header-right-cont">
            <div class="shortkeysBox-btn">
              <a-tooltip
                placement="bottom"
                :title="$t('shortcuts')"
              >
                <div
                  v-shortkey="getFormattedShortcut('Open_Shortcuts_Modal')"
                  style="cursor: pointer;"
                  @click="showModal"
                  @shortkey="showModal"
                >
                  <img src="@/assets/img/shortcuts.svg">
                </div>
              </a-tooltip>
              
              <a-modal
                v-model="showShortcutsModal"
                :title="$t('shortcuts')"
                style="padding: 0px 24px"
              >
                <template slot="footer">
                  <a-button 
                    key="back" 
                    @click="hideShortcutsModal"
                  >
                    {{ $t('close') }}
                  </a-button>
                </template>

                <a-tabs
                  v-shortkey="['esc']"
                  default-active-key="1"
                  @shortkey="hideShortcutsModal"
                >
                  <a-tab-pane 
                    key="1" 
                    :tab="$t('step1')"
                  >
                    <div
                      v-for="(value, key) in this.getShortcutsFormattedWithDescription"
                      :key="key"
                      class="shortcut-line"
                    >
                      <div 
                        v-if="value.category.includes('step1')"
                        class="shortcut-line__content" 
                      >
                        <a-input 
                          v-model="value.description" 
                          :disabled="true" 
                          style="max-width: 200px;" 
                        />
                        <div class="final-shortcut">
                          {{ value.keys }}
                        </div>
                      </div>
                    </div>
                  </a-tab-pane>

                  <a-tab-pane 
                    key="2" 
                    :tab="$t('step2')" 
                    force-render
                  >
                    <div
                      v-for="(value, key) in this.getShortcutsFormattedWithDescription"
                      :key="key"
                      class="shortcut-line"
                    >
                      <div 
                        v-if="value.category.includes('step2')"
                        class="shortcut-line__content"
                      >
                        <a-input 
                          v-model="value.description" 
                          :disabled="true" 
                          style="max-width: 200px;" 
                        />
                        <div class="final-shortcut">
                          {{ value.keys }}
                        </div>
                      </div>
                    </div>
                  </a-tab-pane>

                  <a-tab-pane 
                    key="3" 
                    :tab="$t('recall')"
                  >
                    <div
                      v-for="(value, key) in this.getShortcutsFormattedWithDescription"
                      :key="key"
                      class="shortcut-line"
                    >
                      <div 
                        v-if="value.category.includes('recall')"
                        class="shortcut-line__content" 
                      >
                        <a-input 
                          v-model="value.description" 
                          :disabled="true" 
                          style="max-width: 200px;" 
                        />
                        <div class="final-shortcut">
                          {{ value.keys }}
                        </div>
                      </div>
                    </div>
                  </a-tab-pane>
                </a-tabs>

              </a-modal>

            </div>
            <div class="icons">
              <a-tooltip 
                placement="bottom" 
                :title="$t('saved_bookings')"
              >
                <div style="margin-right: 0;">
                  <SavedBookingsButton 
                    :key="isOpenKey" 
                    :open="isOpen" 
                  />
                </div>
              </a-tooltip>
            </div>
            <div
              v-if="credits && credits.creditLimit"
              id="currentCredit"
              style="width: 185px; line-height: 20px;"
            >
              Current Credit
              <a-icon 
                type="info-circle" 
                @click="showTicketStatus" 
              />

              <span
                style="
                  font-size: 1.7rem;
                  display: block;
                  font-family: 'GothamMedium';
                "
              >
                <span
                  class="reload"
                  style="
                    border: 2px solid lightgray;
                    border-radius: 7px;
                    padding: 1px;
                  "
                  @click="onClickRefreshCredit"
                >
                  <a-icon
                    v-if="refreshingCredit"
                    type="sync"
                    spin
                    style="margin: 2px 3px 4px; vertical-align: middle;"
                  />
                  <img
                    v-else
                    src="~/assets/img/icons/reload-1.svg"
                    alt="reload"
                    style="opacity: 70%; cursor: pointer; margin: 2px 3px 4px;"
                  >
                </span>

                {{ priceWithCommaThousand(credits.currentCredit)+getCurrency }}
              </span>
            </div>
            <div>
              <IdlePopup />
            </div>
            <div class="lang-switcher">
              <el-select
                value=""
                placeholder=""
                @change="changeLanguage"
              >
                <template #prefix>
                  <img
                    v-if="getDefaultLanguage === 'en'"
                    src="../assets/img/Flag_of_the_United_Kingdom.svg"
                    style="height: 10px;"
                    alt="EN Icon"
                  />
                  <img
                    v-else-if="getDefaultLanguage === 'el'"
                    src="../assets/img/Flag_of_Greece.svg"
                    style="height: 12px;"
                    alt="EL Icon"
                  />
                  <img
                    v-else-if="getDefaultLanguage === 'it'"
                    src="../assets/img/Flag_of_Italy.svg"
                    style="height: 12px;"
                    alt="IT Icon"
                  />
                </template>
                <el-option
                  v-for="locale in $i18n.locales"
                  :key="locale.code"
                  :value="locale.code"
                >
                  <img
                    v-if="locale.code === 'en'"
                    src="../assets/img/Flag_of_the_United_Kingdom.svg"
                    style="height: 10px;"
                    alt="EN Icon"
                  />
                  <img
                    v-else-if="locale.code === 'el'"
                    src="../assets/img/Flag_of_Greece.svg"
                    style="height: 12px;"
                    alt="EL Icon"
                  />
                  <img
                    v-else-if="locale.code === 'it'"
                    src="../assets/img/Flag_of_Italy.svg"
                    style="height: 12px;"
                    alt="IT Icon"
                  />
                </el-option>
              </el-select>
            </div>
            <div class="account">
              <a-dropdown
                ref="drop"
                v-model="vis"
                :trigger="['click']"
                :getPopupContainer="(trigger) => trigger.parentNode"
              >
                <div class="avatar-dropdown">
                  <div class="avatar">
                    <img src="@/assets/img/icons/avatar-icon-grey.svg">
                  </div>
                  <div class="avatar__user-company-name">
                    <span
                      style="font-family: 'GothamMedium';"
                    >
                      {{ `${agencyCode}` }}
                    </span>
                    <span class="avatar-fullname">
                      {{ userName }}
                    </span>
                  </div>
                  <div class="avatar-dot">
                    <img style="width: 25px;" src="@/assets/img/icons/dots.png">
                  </div>
                </div>
                <a-menu slot="overlay">
                  <a-menu-item 
                    key="5" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('information')"
                  >
                    <nuxt-link
                      :to="localePath('/info')"
                      event=""
                      @click.native="goTo('info', false)"
                    >
                      <img src="@/assets/img/icons/profile_info.svg">
                      {{ $t('info') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="1" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('settings')"
                  >
                    <nuxt-link
                      :to="localePath('/settings')"
                      event=""
                      @click.native="goTo('settings', false)"
                    >
                      <img src="@/assets/img/icons/menu/settings-drop.svg">
                      {{ $t('menu.settings') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="9" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('activity_log')"
                  >
                    <nuxt-link
                      :to="localePath('/action-log')"
                      event=""
                      @click.native="goTo('action-log', false)"
                    >
                      <img src="@/assets/img/icons/menu/settings-drop.svg">
                      {{ $t('menu.activity_log') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="8" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('shortcuts')"
                  >
                    <nuxt-link
                      :to="localePath('/shortcuts')"
                      event=""
                      @click.native="goTo('shortcuts', false)"
                    >
                      <img src="@/assets/img/icons/menu/shortcuts.svg">
                      {{ $t('menu.shortcuts') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="2" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('alt_agency_code')"
                  >
                    <nuxt-link
                      :to="localePath('/switch-account')"
                      event=""
                      @click.native="goTo('switch-account', false)"
                    >
                      <img src="@/assets/img/icons/menu/switch-account.svg">
                      {{ $t('menu.switch_accounts') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="6" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('change_password')"
                  >
                    <nuxt-link
                      :to="localePath('/change-password')"
                      event=""
                      @click.native="goTo('change-password', false)"
                    >
                      <img src="@/assets/img/icons/menu/change-pass.svg">
                      {{ $t('menu.change_password') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item
                    v-if="!getHideSettings.includes('statistics')"
                    key="10" 
                    @click="() => (vis = false)"
                  >
                    <nuxt-link
                      :to="localePath('/statistics')"
                      event=""
                      @click.native="goTo('statistics', false)"
                    >
                      <img src="@/assets/img/icons/menu/statistics.svg">
                      {{ $t('menu.statistics') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="3" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('cachier_data')"
                  >
                    <nuxt-link
                      :to="localePath('/transaction-history')"
                      event=""
                      @click.native="goTo('transaction-history', false)"
                    >
                      <img src="@/assets/img/icons/menu/balance.svg">
                      {{ $t('menu.transcation_history') }}
                    </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="11" 
                    @click="() => (vis = false)"
                    v-if="!getHideSettings.includes('optional_list')"
                  >
                  <nuxt-link
                    :to="{ path: localePath('/booking-search'), query: { initialActiveKey: '3'} }"
                    event=""
                    @click.native="goTo('booking-search', false, null, '3')"
                  >
                    <img src="@/assets/img/icons/menu/balance.svg">
                    {{ $t('menu.optional_list') }}
                  </nuxt-link>
                  </a-menu-item>
                  <a-menu-item 
                    key="7" 
                    @click="ticketClearances"
                    v-if="!getHideSettings.includes('ticket_clearances')"
                  >
                    <img src="@/assets/img/icons/menu/ticket-clearances.svg">
                    {{ $t('menu.ticket_clearances') }}
                  </a-menu-item>
                  <a-menu-item 
                    key="12" 
                    @click="setFinancialModalStatus(true)"
                    v-if="!getHideSettings.includes('financial_data')"
                  >
                    <img src="@/assets/img/icons/menu/ticket-clearances.svg">
                    {{ $t('menu.financial_data') }}
                  </a-menu-item>
                  <a-menu-item 
                    key="16"
                    @click="showModalCancellationFees"
                    v-if="!getHideSettings.includes('cancellation_fees')"
                  >
                  <img src="@/assets/img/icons/menu/ticket-clearances.svg">
                       {{ $t('cancellation_fees') }}
                  </a-menu-item>
                  <a-menu-item
                    v-if="!getHideSettings.includes('crs_news')"
                    key="13"
                  >
                    <a @click="crsNewsLink()">
                      <img src="@/assets/img/icons/menu/rss.svg">
                      {{ $t('menu.crs_news') }}
                    </a>
                  </a-menu-item>
                  <a-menu-item key="14"
                  v-if="!getHideSettings.includes('ferry_operators_functionality')"
                  >
                    <a 
                      :href="operatorsLink" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <img src="@/assets/img/icons/menu/rss.svg">
                      {{ $t('menu.ferry_operators_functionality') }}
                    </a>
                  </a-menu-item>
                  <a-menu-divider
                    v-if="shouldDisplayOptionalUpdate"
                  />
                  <a-menu-item 
                    key="15"
                    @click="showNotesModal()"
                    v-if="shouldDisplayOptionalUpdate"
                  >
                  <img src="@/assets/img/icons/menu/downloadSVG.svg">
                    {{ $t('menu.optional_update') }}
                  </a-menu-item>
                  <a-menu-divider
                    v-if="shouldDisplayOptionalUpdate"
                  />
                  <a-menu-item 
                    key="4" 
                    @click="logoutClicked"
                  >
                    <img src="@/assets/img/icons/menu/logout-drop.svg">
                    {{ $t('menu.logout') }}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              
              <div 
                v-if="otherAccountName"
                class="other-account-container"
              >
                <div class="avatar">
                  <img src="@/assets/img/icons/menu/switch-account.svg">
                </div>
                <div class="avatar__user-company-name">
                  <span class="name-other-account">
                    {{ `${otherAccountName}` }}
                  </span>
                  <span class="avatar-fullname">
                    {{ otherAccountCode }}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="lang-switcher">
              <el-select
                :placeholder="$t('default_language')"
                :value="getDefaultLanguage"
                @change="changeLanguage"
              >
                <el-option
                  v-for="locale in $i18n.locales"
                  :key="locale.code"
                  :value="locale.code"
                >
                  {{ locale.code }}
                </el-option>
              </el-select>
            </div> -->
          </div>
        </div>
      </Header>
      <Content @contextmenu.prevent.stop="handleClick($event)">
        <nuxt />
      </Content>
    <vue-simple-context-menu
      element-id="vue_simple_context_menu"
      :options="menuOptions"
      ref="vueSimpleContextMenu"
      @option-clicked="optionClicked"
    />
    </Layout>
    <VSnow v-if="isDev" :density="snowOptions.density" />
  </Layout>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import pathOr from 'ramda/src/pathOr'
import moment from 'moment'
import { Layout, Menu } from 'ant-design-vue'
import SavedBookingsButton from '../components/BookTicket/SavedBookingsButton'
import SaveBookingStateButton from '../components/BookTicket/SaveBookingStateButton'
import { priceWithCommaThousand, priceWithComma } from '@/helpers/format'
import KeyboardEvents from '@/components/BookTicket/KeyboardEvents'
import { createStorage } from '@/helpers/storage'
import { onSuccess } from '@/helpers/validations'
import { Message } from 'element-ui';
import LeaveModal from '~/components/LeaveModal/index.vue'
import {VSnowContainer} from 'vsnow'
import IdlePopup from '../components/IdlePopup/index.vue';
const { Sider, Header, Content } = Layout
const { Item } = Menu
import { EventBus } from "@/plugins/event-bus";

const ipcRenderer = process.env.ENVIRONMENT === 'electron' ? require('electron').ipcRenderer : null
const shell = process.env.ENVIRONMENT === 'electron' ? require('electron').shell : null

export default {
  middleware: ['auth'],
  components: {
    SavedBookingsButton,
    SaveBookingStateButton,
    Layout,
    Sider,
    Header,
    Content,
    IdlePopup,
    Menu,
    Item,
    KeyboardEvents,
    LeaveModal,
    VSnow: VSnowContainer
  },
  data() {
    return {
      cancellationTicketLetter:'',
      cancellationTicketNumber:'',
      cancellationResults:{},
      cancellationResultsHaveError:false,
      cancellationTicket:{
         "ticketLetter":"",
         "ticketNumber":""
      },
      haveCancellationResult:false,
      visibleCancellationFees:false,
      showShortcutsModal: false,
      selectAll: false,
      continueAction: false,
      financialModal: false,
      collapsed: false,
      loading: false,
      loadingFinancial: false,
      confirmPopup: false,
      isOpen: false,
      isOpenKey: 0,
      vis: false,
      companies: [],
      financialCompanies: [],
      financialDataRes: {},
      fileTypes: [{ name: 'pdf' }, { name: 'txt' }, { name: 'xls' }],
      refreshingCredit: false,
      showTicketStatusModal: false,
      clearancesModal: false,
      selectedCompany: undefined,
      clearanceNumber: '',
      menuLink: '',
      selectFileType: 'pdf',
      redirectLink: '',
      query: '',
      clearanceYear: '',
      error: false,
      actionToPerform: '',
      isConnected: null,
      isConnectionSeen: false,
      updateModal: false,
      windowWidth: window.innerWidth,
      pickerOptions: {
        disabledDate(current) {
          return current > moment()
        },
      },
      getcompaniesForSearchInterval: null,
      platform: require('os').platform(),
      snowOptions: {
          density: 8,
      },
      menuOptions: [],
      cdnContent: '',
      cdnImage:'', // Νέα μεταβλητή για το περιεχόμενο από το CDN
      isUpdating: false,
      downloadProgress: 0,
    }
  },
  watch: {
    getFinancialModalStatus(newValue, oldValue) {
      if (newValue === true && this.getSelectedTripAbbreviation.length > 0) {
        this.financialCompanies = [this.getSelectedTripAbbreviation];
        this.financialDataRes = {};
        this.handleFinancial();
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrency: 'settings/getCurrency',
      getDefaultColor: 'settings/getDefaultColor',
      getSelectedTrips: 'book-ticket/getSelectedTrips',
      getNotificationCloseStatus: 'settings/getNotificationCloseStatus',
      pricingTrips: 'book-ticket/getPricingTrips',
      getDefaultLanguage: 'settings/getDefaultLanguage',
      availableConfigurations: 'getAvailableConfigurations',
      getSession: 'user/getSession',
      getOtherAccount: 'book-ticket/getOtherAccount',
      getShortcutsFormatted: 'settings/getShortcutsFormatted',
      credits: 'getCredits',
      getFinancialModalStatus: 'book-ticket/getFinancialModalStatus',
      getSelectedTripAbbreviation: 'book-ticket/getSelectedTripAbbreviation',
      redirectionLinks: 'redirectionLinks',
      getcompaniesForSearch: 'book-ticket/getcompaniesForSearch',
      getCompanies: 'book-ticket/getCompanies',
      appVersion: 'app/getAppVersion',
      getAvailablePrintersFromStore: 'book-ticket/getAvailablePrinters',
      getHideSettings: 'settings/getHideSettings',
    }),
    getShortcutsFormattedWithDescription() {
      Object.entries(this.getShortcutsFormatted).forEach(([key, value]) => {
        value.description = this.$t('shortCut_' + key)
        this.$t('shortCut_' + key, this.$i18n.locale)
      });

      return this.getShortcutsFormatted
    },
    filteredCompanies() {
      if (!this.searchQuery) {
        return this.getcompaniesForSearch;
      }
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.getcompaniesForSearch.filter(company => company.description.toLowerCase().includes(lowerCaseQuery));
    },
    operatorsLink(){
      return process.env.OPERATORS_LINK
    },
    otherAccountName() {
      return pathOr(undefined, ['alternateAgencyName'], this.getOtherAccount)
    },
    otherAccountCode() {
      return pathOr(undefined, ['credentials'], this.getOtherAccount)
    },
    userChannel() {
      return pathOr('-', ['agencyPrintingData', 'channel'], this.getSession)
    },
    canManageTruckCompanies() {
      return pathOr(
        false,
        ['permissions', 'canManageTruckCompanies'],
        this.availableConfigurations,
      )
    },
    canCheckIn() {
      return pathOr(
        false,
        ['permissions', 'trip', 'canCheckIn'],
        this.availableConfigurations,
      )
    },
    userName() {
      return pathOr('-', ['userName'], this.getSession)
    },
    agencyCode() {
      return pathOr('no code', ['agencyCode'], this.getSession)
    },
    isDev() {
      return process.env.IS_DEV === 'true'
    },
    shouldDisplayOptionalUpdate() {
      return ipcRenderer && (pathOr('', ['versioningStatus'], this.getSession) == 'OPTIONAL_UPDATE')
    }
  },
  created() {
    if (!ipcRenderer) return
    // The code below is only for Electron
    ipcRenderer.on('connectivity-report', (event, { noConnection }) => {
      if (!noConnection) this.isConnectionSeen = false
      if (noConnection && !this.isConnectionSeen) {
        this.isConnectionSeen = true    
        Message({
          message: 'No Internet Connection',
          type: 'error',
          showClose: this.getNotificationCloseStatus ? true : false,
          duration: this.getNotificationCloseStatus ? 1000000 : 3000
        });
      }
    })
  },
  async mounted() {
    console.log(this.getSession);
    this.fetchCdnContent();
    await this.fetchAndFormatPrinters()

    if (ipcRenderer) {
      // Only for Electron
      this.checkTerratecFile()
      setInterval(() => {
        ipcRenderer.send('check-connectivity')
      }, 3000)

      ipcRenderer.on('download-progress', (event, progress) => {
        this.downloadProgress = Math.round(progress.percent * 100);
      });

      ipcRenderer.on('update-error', (event, error) => {
        console.error('Update error received:', error);
        this.isUpdating = false;
        this.$message.error(this.$t('update_error'));
      });

      ipcRenderer.on('update-completed', () => {
        this.isUpdating = false;
        this.downloadProgress = 100;
        this.$message.success(this.$t('update_completed'));

        // Close the app after 2 seconds
        setTimeout(() => {
          window.close();
        }, 2000);
      });
    }

    this.getCountries()
    this.configurations()
    this.handleCompaniesForSearch()
    // this.getFinancialData()
    this.disableF12()
    this.setCompanyRedirections()
    // Update app flow
    // if(require('os').platform() !== 'darwin') {
      if(this.getSession.versioningStatus === "OPTIONAL_UPDATE" && process.env.ENVIRONMENT !== 'web') {
        this.updateModal = true
      }
    // }
      
    const storage = createStorage(localStorage);
    const savedTcpIp = storage.get('erpIpSetting');
    const savedTcpPort = storage.get('erpPortSetting');

    this.setTcpIp(savedTcpIp);
    this.setTcpPort(savedTcpPort);
    
    const keepNotificationsOpen = storage.get('keepNotificationsOpen')
    this.setNotificationCloseStatus(keepNotificationsOpen);

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    })
  },
  methods: {
    async fetchCdnContent() {
      try {
        const cdnUrl = process.env.RELEASE_NOTES;
        const response = await fetch(cdnUrl);
        const arrayBuffer = await response.arrayBuffer();
        const decoder = new TextDecoder('iso-8859-7'); // Ή 'iso-8859-7' αν χρειάζεται
        const dataString = decoder.decode(arrayBuffer);

        function parseNewVersionData(dataString) {
          const startMarker = 'const newVersionData =';
          const endMarker = 'export default newVersionData;';
          const startIndex = dataString.indexOf(startMarker);
          const endIndex = dataString.lastIndexOf(endMarker);

          if (startIndex === -1 || endIndex === -1) {
            console.error('Cannot find newVersionData in dataString');
            return null;
          }

          const arrayString = dataString.substring(startIndex + startMarker.length, endIndex).trim();

          try {
            const arrayData = (new Function('return ' + arrayString))();
            return arrayData;
          } catch (e) {
            console.error('Error parsing arrayString:', e);
            return null;
          }
        }

        const newVersionData = parseNewVersionData(dataString);

        if (newVersionData) {
          const defaultLanguage = this.getDefaultLanguage; // π.χ., 'en', 'el'

          // Αρχικοποίηση των μεταβλητών
          let languageContent = '';
          let languageImage = '';

          // Διατρέχουμε το newVersionData για να βρούμε το κείμενο και την εικόνα
          for (const item of newVersionData) {
            if (item.hasOwnProperty(defaultLanguage)) {
              languageContent = item[defaultLanguage];
            }
            if (item.hasOwnProperty(`image_${defaultLanguage}`)) {
              languageImage = item[`image_${defaultLanguage}`];
            }
          }

          // Αν δεν βρέθηκε κείμενο στη γλώσσα του χρήστη, χρησιμοποιούμε τα Αγγλικά
          if (!languageContent) {
            const englishContentItem = newVersionData.find(item => item.hasOwnProperty('en'));
            if (englishContentItem) {
              languageContent = englishContentItem['en'];
            }
          }

          // Αν δεν βρέθηκε εικόνα στη γλώσσα του χρήστη, χρησιμοποιούμε τα Αγγλικά
          if (!languageImage) {
            const englishImageItem = newVersionData.find(item => item.hasOwnProperty('image_en'));
            if (englishImageItem) {
              languageImage = englishImageItem['image_en'];
            }
          }

          // Αποθήκευση του κειμένου και της εικόνας στα data
          this.cdnContent = languageContent;
          this.cdnImage = languageImage;

        } else {
          console.error('Failed to parse newVersionData');
          this.cdnContent = '';
          this.cdnImage = '';
        }

      } catch (error) {
        console.error('Error fetching CDN content:', error);
      }
    },
    showNotesModal(){
      this.updateModal = true 
    },
    crsNewsLink() {
      const currentLocale = this.$i18n.locale.toUpperCase()
      if (process.env.ENVIRONMENT === 'electron') {
        if (currentLocale === 'EN') shell.openExternal(process.env.ANNOUNCEMENTS_EN)
        else shell.openExternal(process.env.ANNOUNCEMENTS_EL)
      } else {
        if (currentLocale === 'EN') window.open(process.env.ANNOUNCEMENTS_EN)
        else window.open(process.env.ANNOUNCEMENTS_EL)
      }
    },
    showModalCancellationFees(){
      this.visibleCancellationFees = true
    },
    onTicketLetterInputChange (key, value) {
      this.cancellationTicketLetter = value && value.toUpperCase()
      this.cancellationTicket.ticketLetter = value && value.toUpperCase()
    },
    onTicketNumberInputChange (key, value) {
      this.cancellationTicketNumber = value
      this.cancellationTicket.ticketNumber = value
    
    },
    setModalVisible(modalVisible) {
      this.visibleCancellationFees = modalVisible;
    },
    handleClick(event) {
      let step = "";
      let tempMenuOptions = [];
        
      // Take current step
      if (this.$route.path.indexOf("booking-search") > 0) {
        step = "recall"
      } else if (this.$route.path.indexOf("step-2") > 0) {
        step = "step2"
      } else if (this.$route.path.indexOf("book-ticket") > 0) {
        step = "step1"
      } else {
        return;
      }

      // Take all shorcuts for current step
      Object.values(this.getShortcutsFormattedWithDescription).map((shortcut, index) => {
        if (shortcut.category.includes(step)) {
          shortcut.keys = shortcut.keys
              // Insert a space before all caps
              .replaceAll(",", "+ ")
              // Uppercase the first character
              .replace(/^./, (str) => str.toUpperCase())
              // Uppercase the first character of every word follows a space and delete space
              .replaceAll(/ ([a-z])/g, function (g) { return g[1].toUpperCase() });
              
          // console.log(shortcut.keys);

          if (EventBus._events[Object.keys(this.getShortcutsFormattedWithDescription)[index]]
            || Object.keys(this.getShortcutsFormattedWithDescription)[index] === "Latest_Booking"
            || Object.keys(this.getShortcutsFormattedWithDescription)[index] === "Open_Shortcuts_Modal"
            || Object.keys(this.getShortcutsFormattedWithDescription)[index] === "Search_By_Reservation"
            || Object.keys(this.getShortcutsFormattedWithDescription)[index] === "Search_By_Ticket"
          ) {
            tempMenuOptions.push({
              name: `<div>${shortcut.description}</div><div class="fw-bold">${shortcut.keys}</div>`,
              slug: Object.keys(this.getShortcutsFormattedWithDescription)[index],
            });
          }
        }
      })

      // Split into sub-tables with certain options
      let firstArray = this.getArrayElement(tempMenuOptions, "Open_Shortcuts_Modal");
      let lastArray = this.getArrayElement(tempMenuOptions, "Clear") || this.getArrayElement(tempMenuOptions, "Cancel_Ticket") || this.getArrayElement(tempMenuOptions, "New_Search");

      // Ascending sort by name
      tempMenuOptions = tempMenuOptions ? this.ascendingArraySort(tempMenuOptions) : [];
      firstArray = firstArray ? this.ascendingArraySort(firstArray) : [];
      lastArray = lastArray ? this.ascendingArraySort(lastArray) : [];

      // Add dividers
      if (firstArray.toString() && tempMenuOptions.toString()) {
        firstArray.push({ type: "divider" });
      }
      if (tempMenuOptions.toString() && lastArray.toString()) {
        tempMenuOptions.push({ type: "divider" });
      }

      // Concat all arrays into one
      this.menuOptions = firstArray.concat(tempMenuOptions).concat(lastArray)

      this.$refs.vueSimpleContextMenu.showMenu(event)
    },
    optionClicked(event) {
      const slug = event.option.slug;
      
      if (slug === "Latest_Booking") {
        this.latestBooking();
      } else if (slug === "Open_Shortcuts_Modal") {
        this.showModal();
      } else if (slug === "Search_By_Reservation") {
        this.searchByResNumber();
      } else if (slug === "Search_By_Ticket") {
        this.searchByTicketNumber();
      } else {
        EventBus.$emit(slug);
      }
    },
    ascendingArraySort(array) {
      array.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      return array;
    },
    getArrayElement(array, key) {
      const index = array.findIndex((el)=> el.slug.indexOf(key) === 0);
      return index > -1 ? array.splice(index, 1) : null
    },
    getFormattedShortcut(action) {
      const shortcutFormatted = this.getShortcutsFormatted;
      return shortcutFormatted && shortcutFormatted[action] ? shortcutFormatted[action].keys.split(',') : null;
    },
    showModal() {
      this.showShortcutsModal = true;
    },
    hideShortcutsModal(e) {
      this.showShortcutsModal = false;
    },

    closeModal() {
      this.financialCompanies = [];
      this.selectAll = false;
      this.setFinancialModalStatus(false);
      this.financialDataRes = {};
    },
    
    priceWithCommaThousand(query) {
      this.searchQuery = query;
    },

    getTypeIcon(string) {
      if (string.includes("TICKETS") || string.includes("Tickets")) {
        return require('@/assets/img/icons/bookaticket.svg');
      } else if (string.includes('CREDIT LIMIT') || string.includes('Credit Limit')) {
        return require('@/assets/img/icons/booking.svg');
      } else {
        return require('@/assets/img/icons/money-icon.png');
      }
    },

    priceWithComma,
    moment,
    ...mapMutations({
      storeAvailablePrinters: 'book-ticket/STORE_AVAILABLE_PRINTERS',
      storeAvailableCompanies: 'book-ticket/STORE_AVAILABLE_COMPANIES',
      setCompaniesForSearch: 'book-ticket/SET_COMPANIES_FOR_SEARCH',
      setBookingRecall: 'booking-recall/SET_BOOKING_RECALL',
      setTcpIp: 'settings/SET_TCP_IP',
      setTcpPort: 'settings/SET_TCP_PORT',
      setNotificationCloseStatus: 'settings/SET_NOTIFICATION_CLOSE_STATUS'
    }),
    ...mapActions({
      logout: 'user/logout',
      getCountries: 'book-ticket/getCountries',
      resetState: 'book-ticket/resetState',
      getAvailablePrinters: 'book-ticket/getAvailablePrinters',
      resetRecallState: 'booking-search/resetRecallState',
      saveCurrentStateToStorage: 'book-ticket/saveCurrentStateToStorage',
      loadSavedState: 'book-ticket/loadSavedState',
      retrieveLatestBooking: 'book-ticket/retrieveLatestBooking',
      getAvailableCompanies: 'book-ticket/getAvailableCompanies',
      getRoutes: 'book-ticket/getRoutes',
      getClearanceDownloadIdAction: 'book-ticket/getClearanceDownloadIdAction',
      downloadClearanceAction: 'book-ticket/downloadClearanceAction',
      findCompanies: 'book-ticket/getAvailableCompaniesSearch',
      unlockAccomodationsSeatMap: 'book-ticket/unlockAccomodationsSeatMap',
      // getAvailableCompaniesPrinters: 'book-ticket/getAvailableCompaniesPrinters',
      getFinancialData: 'getFinancialData',
      getCancellationFees: 'getCancellationFees',
      setFinancialModalStatus: 'book-ticket/setFinancialModalStatus',
      configurations: 'getAvailableConfigurations',
      setSettings: 'settings/setSettings',
      setCompanyRedirections: 'setCompanyRedirections',
      saveSettingsToApi: 'settings/saveSettingsToApi',
      unlockSeatAccomodations: 'book-ticket/unlockSeatAccomodations',
    }),
    async logoutClicked() {      
      await this.unlockSeatAccomodations({ name: "logout" })
      await this.logout()
    },
    async performUpdate(){
      let newVersionDownloadUrl = pathOr('', ['newVersionDownloadLink'], this.getSession)
      this.isUpdating = true;

      await ipcRenderer.invoke('update-app', { newVersionDownloadUrl })
        .then(result => {
          console.log('Update initiated successfully:', result);
        })
        .catch(error => {
          console.error('Error during update initiation:', error);
          this.isUpdating = false;
        });
    },
    closeConfirmPopup() {
      this.confirmPopup = false
    },

    async changeLanguage(code) {
      try {
        const storage = createStorage(localStorage);
        storage.set('defaultLang', code);
        
        this.setSettings({ value: code, mutation: 'SET_DEFAULT_LANGUAGE' });

        $nuxt.$i18n.locale = code;

        if (this.$route.path.includes('step-2')) {
          await this.$router.push({ path: this.switchLocalePath(code)});
        } else {
          await this.getRoutes();
          await this.$router.push({ path: this.switchLocalePath(code)});
        }
      } catch (error) {
        Message({
            message: error,
            type: 'error',
            showClose: this.getNotificationCloseStatus ? true : false,
            duration: this.getNotificationCloseStatus ? 1000000 : 5000
        });
      }
    },

    handleCompaniesForSearch() {
      this.findCompanies().then((companies) => {
        this.setCompaniesForSearch(companies)
      })
    },
    fetchAndFormatPrinters() {
      const stringToArray = (res) => {
        return res
          .split(/\r\n|\r|\n/g)
          .splice(1)
          .filter((i) => i)
          .map((p) => p.split('='))
      }
      this.getAvailablePrinters().then((res) => {
        this.storeAvailablePrinters(stringToArray(res))
      })
      // this.getAvailableCompaniesPrinters().then(res => {
      //   const stringToArray = res => {
      //     return res
      //       .split(/\r\n|\r|\n/g)
      //       .splice(1)
      //       .filter(i => i)
      //       .map(p => p.split('=')[1].split(','))
      //   }
      //   this.storeAvailableCompanies(stringToArray(res))
      // })
    },
    showTicketStatus() {
      this.showTicketStatusModal = true
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    },
    closeMenu() {
      this.vis = false
      this.showTicketStatusModal = false
      this.clearancesModal = false
      this.financialModal = false
    },
    selectCompany(value) {
      this.selectedCompany = value
    },
    selectFiletype(value) {
      this.selectFileType = value
    },
    keyboardEvent(e) {
      if (
        e.keyCode === 13 &&
        (document.activeElement.id || document.getElementById('axa'))
      ) {
        document.getElementById(document.activeElement.id).focus()
        document.getElementById(document.activeElement.id).click()
      }
      
      if (e.key === "F4") {
        if (document.activeElement.tagName !== "INPUT") {
          EventBus.$emit("copySelectInputValue")
        }
      }
    },
    downloadClearance() {
      this.loading = true
      const year = moment(this.clearanceYear).format('YYYY')
      this.getClearanceDownloadIdAction({
      year: this.clearanceYear ? year : undefined,
      number: this.clearanceNumber || undefined,
      company_abbreviation: this.selectedCompany,
      fileType: this.selectFileType,
    })
      .then(
        onSuccess(this, (res) => {
          this.loading = false
          window.open(res.url)
        }),
        (err) => {
          Message({
            message: err,
            type: 'error',
            showClose: this.getNotificationCloseStatus ? true : false,
            duration: this.getNotificationCloseStatus ? 1000000 : 3000
          });
        },
      )
      .finally(() => {
        this.clearancesModal = false
        this.clearanceYear = ''
        this.clearanceNumber = ''
        this.selectedCompany = undefined
      })
    },
    ticketClearances() {
      this.vis = false
      this.clearancesModal = true
      // this.getAvailableCompanies().then(res => (this.companies = res))
    },

    financialData() {
      this.vis = false
      this.financialModal = true
    },
    handleFinancial() {
      if (this.selectAll) {
        this.financialCompanies = this.getcompaniesForSearch.map((element, index) => {
          return index;
        });
        this.loadingFinancial = true
        this.getFinancialData(this.financialCompanies.map(c => this.getcompaniesForSearch[c].abbreviation)).then(res => {
          this.financialDataRes = res
          this.loadingFinancial = false
        })
      } else {
        if (this.getSelectedTripAbbreviation !== "") {
          this.loadingFinancial = true
          this.getFinancialData(this.getSelectedTripAbbreviation).then(res => {
            this.financialDataRes = res
            if (Object.entries(res).length === 0) {
              this.error = true
            }
            this.loadingFinancial = false
          })
        } else {
          this.loadingFinancial = true
          this.getFinancialData(this.financialCompanies.map(c => this.getcompaniesForSearch[c].abbreviation)).then(res => {
            this.financialDataRes = res
            this.loadingFinancial = false
          })
          if (Object.entries(this.financialDataRes).length === 0) {
            this.error = true
          }
          this.loadingFinancial = false
        }
      }
    },
    handleCancelationFees() {
      if (this.cancellationTicket.ticketLetter == '' || this.cancellationTicket.ticketNumber == '') {
        Message({
          message: this.$t('ticket_letter_number_miss'),
          type: 'error',
          showClose: this.getNotificationCloseStatus ? true : false,
          duration: this.getNotificationCloseStatus ? 1000000 : 3000
        });
      } else {
        this.loadingFinancial = true
        this.getCancellationFees(JSON.stringify(this.cancellationTicket))
          .then(res => {
            if (res.code) {
              this.cancellationResultsHaveError = true
              this.loadingFinancial = false
            } else {
              this.cancellationResults = res;
              this.haveCancellationResult = true
              this.cancellationResultsHaveError = false
              this.loadingFinancial = false
            }
          })
          .catch(error => {
            this.loadingFinancial = false
            Message({
              message: this.$t('no_financial_result'),
              type: 'error',
              showClose: this.getNotificationCloseStatus ? true : false,
              duration: this.getNotificationCloseStatus ? 1000000 : 3000
            });
          });
      }
    },
    disableF12() {
      // test
      // window.oncontextmenu = function() {
      //   return false
      // }
      document.onkeydown = function (e) {
        if (window.event.keyCode === 123) return false
      }
    },
    latestBooking() {
      this.retrieveLatestBooking().then((data) => {
        this.setBookingRecall(data)
        this.goTo(`booking-search/${data.crsReservationId}`, false)
      })
    },
    onClickRefreshCredit() {
      Promise.resolve((this.refreshingCredit = true))
        .then(this.getFinancialData)
        .catch((er) => this.$notification.error({message: 'Could not get credit data', duration: this.getNotificationCloseStatus ? 0 : 4.5, }))
        .finally(() => (this.refreshingCredit = false))
    },
    goTo(link, move, searchByQuery, initialActiveKeyQuery) {
      this.menuLink = link
      if (
        this.$route.path.includes('step3') ||
        this.$route.path.includes('step-2')
      ) {
        if (move) {
          this.confirmPopup = false
          this.$router.push({
            path: this.localePath(`/${link}`),
            query: { initialActiveKey: initialActiveKeyQuery, searchBy: searchByQuery }
          })
        } else {
          this.confirmPopup = true
          this.actionToPerform = 'menuLink'
        }
      } else {
        this.$router.push({
          path: this.localePath(`/${link}`),
          query: { initialActiveKey: initialActiveKeyQuery, searchBy: searchByQuery }
        })
      }
    },
    openSave() {
      this.isOpen = true
      this.isOpenKey = this.isOpenKey + 1
      this.confirmPopup = false
    },
    async handleLeave() {
      try {
        const atLeastOneSeatIsSelected = this.getSelectedTrips
          .some(trip => trip.prices.some(price => price.accommodationNumber) || Object.values(trip.selectedPassengers)
            .some(passenger => (passenger.selectedSeat && Object.keys(passenger.selectedSeat).length) || (passenger.price && passenger.price.accommodationNumber))
          )
        // Unlock accomodations only when needed. That is only when at least on seat is selected from the seat-map.
        if (atLeastOneSeatIsSelected) {
          const trip = this.getSelectedTrips[0]
          const pricingTrip = this.pricingTrips[0]
          this.unlockAccomodationsSeatMap({
            trip: {
              departureDateTime: trip.departureDateTime,
              origin: { idOrCode: trip.origin.idOrCode },
              destination: { idOrCode: trip.destination.idOrCode },
              vessel: {
                idOrCode: trip.vessel.idOrCode,
                company: {
                  abbreviation: trip.vessel.company.abbreviation,
                },
              },
            },
            planItems: pricingTrip?.prices.map(
              ({ itemIdOrCode, accommodationNumber }) => {
                return {
                  accommodation: {
                    idOrCode: itemIdOrCode,
                  },
                  accommodationNumber,
                }
              },
            ),
            returnPlan: false,
          })
        }
        if (this.actionToPerform === 'resetBooking') this.resetBooking(true)
        if (this.actionToPerform === 'resetRecall') this.resetRecall(true)
        if (this.actionToPerform === 'resetGroupRecall')
          this.resetGroupRecall(true)
        if (this.actionToPerform === 'redirect')
          this.redLinks(true, this.redirectLink)
        if (this.actionToPerform === 'menuLink') this.goTo(this.menuLink, true, this.query)
      } catch (error) {
        Message({
          message: error,
          type: 'error',
          showClose: this.getNotificationCloseStatus ? true : false,
          duration: this.getNotificationCloseStatus ? 1000000 : 3000
        });
      }
    },
    handleCloseSave() {
      this.isOpen = false
      this.handleLeave()
    },
    resetBooking(move = false) {
      if (
        this.$route.path.includes('step3') ||
        this.$route.path.includes('step-2')
      ) {
        if (move) {
          this.confirmPopup = false
          this.resetState()
          this.handleCompaniesForSearch()
          this.$router.push({ path: this.localePath('/book-ticket') })
        } else {
          this.confirmPopup = true
          this.actionToPerform = 'resetBooking'
        }
      } else {
        this.resetState()
        // this.handleCompaniesForSearch()
        this.$router.push({ path: this.localePath('/book-ticket') })
      }
    },
    resetRecall(move) {
      if (
        this.$route.path.includes('step3') ||
        this.$route.path.includes('step-2')
      ) {
        if (move) {
          this.confirmPopup = false
          this.resetRecallState()
          this.resetState()
          this.$router.push({ path: this.localePath('/booking-search') })
        } else {
          this.confirmPopup = true
          this.actionToPerform = 'resetRecall'
        }
      } else {
        this.resetRecallState()
        this.resetState()
        this.$router.push({ path: this.localePath('/booking-search') })
      }
    },
    resetGroupRecall(move) {
      if (
        this.$route.path.includes('step3') ||
        this.$route.path.includes('step-2')
      ) {
        if (move) {
          this.confirmPopup = false
          this.$router.push({ path: this.localePath('/booking-group-search') })
        } else {
          this.confirmPopup = true
          this.actionToPerform = 'resetGroupRecall'
        }
      } else {
        this.$router.push({ path: this.localePath('/booking-group-search') })
      }
    },
    redLinks(move, link) {
      this.redirectLink = link
      if (
        this.$route.path.includes('step3') ||
        this.$route.path.includes('step-2')
      ) {
        if (move) {
          this.confirmPopup = false
          window.open(link, '_blank')
        } else {
          this.confirmPopup = true
          this.actionToPerform = 'redirect'
        }
      } else {
        window.open(link, '_blank')
      }
    },
    searchByResNumber() {
      window.focus()
      this.query = 'resNumber'
      this.goTo('booking-search', false, 'resNumber', '0')
      return
    },
    searchByTicketNumber() {
// this.confirmPopup = true
      this.query = 'ticketNumber'
      this.goTo('booking-search', false, 'ticketNumber', '1')
      return
    },
    /**
     * Checks terratec.ini file only once using the 'terratec-check' flag
     * stored in local storage. When the terratec.ini file is checked,
     * the exported settings are then stored in local storage.
     */
    async checkTerratecFile () {
      const storage = createStorage(localStorage)
      const checkedForTerratec =  storage.get('terratec-check', false)
      if (!checkedForTerratec) {
        const initialSettings = await ipcRenderer.invoke('get-initial-settings')
        console.log({ initialSettings })
        this.getcompaniesForSearchInterval = setInterval(async () => {
          if (this.getcompaniesForSearch?.length) {
            clearInterval(this.getcompaniesForSearchInterval)
            storage.set('terratec-check', true)
            if (!('error' in initialSettings)) {
              const model = this.getAvailablePrintersFromStore.find(printer => printer[0] === initialSettings.printerSettings.model)
              if (model) {
                initialSettings.printerSettings.id = model[1]
              }
              this.savePrinterToLocal(storage, initialSettings.printerSettings)
              if (initialSettings.login) {
                this.savePrinterLoginToLocal(storage, initialSettings.login)
              }
              if (initialSettings.defaultCompany?.abbreviation) {
                const defaultCompany = this.getcompaniesForSearch.find(c => c.abbreviation === initialSettings.defaultCompany.abbreviation)
                if (defaultCompany) {
                  await this.setSettings({ value: defaultCompany, mutation: 'SET_DEFAULT_COMPANY' })
                }
              }
              if (initialSettings.showCompany !== null) {
                await this.setSettings({ value: initialSettings.showCompany, mutation: 'SET_SHOW_COMPANY' })
              }
              if (initialSettings.defaultDayToday !== null) {
                await this.setSettings({ value: initialSettings.defaultDayToday, mutation: 'SET_DEFAULT_DATE' })
              }
              this.saveSettingsToApi()
            }
          }
        }, 10)
      }
    },
    savePrinterToLocal(storage, { model, printer, id }) {
      storage.set('printerInfo', { model, printer, id })
    },
    savePrinterLoginToLocal(storage, data) {
      storage.set('printerLogin', data)
    },
     onResize() {
      this.windowWidth = window.innerWidth
      if(this.windowWidth < 1500) this.collapsed = true
    },
  },
}
</script>
<style lang="scss">
.reload {
  .anticon-spin {
    width: 14px;
    height: 14px;
  }
}
.cdnImage{
  max-width: 100%;
}
.cancellation-results--items{
  padding: 0 5%;
}
.cancellation--items_title  {
  font-size: 16px;
  margin-bottom: 5px;
}
  .cancellation--items_value{
    margin-bottom: 5px;
  font-weight: 600;
  font-size: 1.8rem;
    color: var(--main-color);
}


.cancellation--items_container{
  justify-content: space-between;
  padding: 1% 0;
}
.ticket-stock-info {
  .cont {
    margin-right: 15px;
    width: 32%;
    .ant-select {
      width: 100%;
      height: 40px;
    }
    .ant-select-selection__rendered,
    .ant-select-selection--single {
      height: 40px;
      .ant-select-selection-selected-value {
        padding-top: 5px;
        min-height: 40px;
      }
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    input {
      width: 100%;
      height: 40px;
    }
  }
}
.ticket-clearances {
  .cont {
    margin-right: 15px;
    width: 32%;
    &.num {
      width: 20%;
    }
    &.type {
      width: 20%;
    }
    .ant-select {
      width: 100%;
      height: 40px;
    }
    .ant-select-selection__rendered,
    .ant-select-selection--single {
      height: 40px;
      .ant-select-selection-selected-value {
        padding-top: 5px;
        min-height: 40px;
      }
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    input {
      width: 100%;
      height: 40px;
    }
  }
}

.ant-layout{
      min-width: 1450px !important;
}
.ant-menu-submenu.ant-menu-submenu-placement-rightTop ul {
  padding-left: 0 !important;
  padding-right: 0 !important;
  li a {
    padding-left: 20px !important;
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: transparent !important;
  color: rgba(255, 255, 255, 0.65) !important;
  a {
    color: rgba(255, 255, 255, 0.65) !important;
  }
}
.ant-layout-sider-children ul.ant-menu li:nth-child(6) {
  margin: 30px 0;
  border-bottom: dashed 1px white;
  height: 0;
}
.ant-layout-sider-collapsed .poweredby{
  display: flex;
  align-items: flex-end;
  font-size: .8em;

  .liknoss-logo__powered, small, a { display: none; }
}

.liknoss-logo {
  &__powered {
    line-height: 1;
    margin-bottom: .25em;
    white-space: nowrap;
  }

  &__image {
    flex: 0 0 100%;
    vertical-align: middle;
    width: 86px;
    height: auto;
  }
}
.poweredby {
  position: absolute;
  gap: 1em;
  bottom: 20px;
  left: 50%;
  transform-origin: bottom left;
  transform: translate(-50%, 0);
  color: rgba(255, 255, 255, 0.65) !important;
  will-change: transform;
  transition: all linear 0.2s;

  &__terms {
    display: inline-block;
    padding-top: 1em;
    white-space: nowrap;
  }
} 

.ant-layout-content {
  padding: 5px 4px 5px 10px;
  background: #f1f2f8;
  min-height: auto !important;
}

// burger button
.burger {
  position: relative;
  top: 2%;
  left: 12px;
  width: 25px;
  height: 35px;
  cursor: pointer;
  &:hover {
    .line-one {
      transition: all ease 0.5s;
      transform: translateY(-1px);
    }
    .line-two {
      transition: all ease 0.5s;
      transform: translate(-2px, -13px);
    }
    .line-three {
      transition: all ease 0.5s;
      transform: translateY(-26px);
    }
  }
  .line {
    background: white;
    width: 100%;
    height: 2px;
    position: relative;
    display: inline-block;
    transition: all ease 0.5s;
  }
  .line-one {
    transform: translateY(0px);
  }
  .line-two {
    transform: translateY(-13px);
  }
  .line-three {
    transform: translateY(-27px);
  }
}
#components-layout-demo-custom-trigger {
 height: 100vh;
  // fix this
  .ant-layout-sider.ant-layout-sider-dark {
    background: #080521;
    flex: 0 0 205px !important;
    max-width: 205px !important;
    min-width: 205px !important;
    width: 205px !important;
  }

  .ant-layout-sider-collapsed {
    ul.ant-menu-inline-collapsed {
      .ant-menu-submenu-title {
        padding: 0 !important;
      }
      li.ant-menu-submenu-open {
        position: relative;
        ul {
          position: absolute;
          z-index: 1;
          width: 180px;
          left: 65px;
          top: 0;
        }
      }
    }
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    flex: 0 0 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    width: 65px !important;
    .ant-menu {
      li.ant-menu-item {
        span {
          opacity: 0;
          transition: opacity ease-in-out 0.5s;
        }
      }
    }
  }
  .otherAccount {
    color: var(--main-color);
    border-color: var(--main-color);
  }
  // Header
  .ant-layout-header {
    justify-content: space-between;
    background: #fff;
    height: 75px;
    padding: 0;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    line-height: inherit;
    &.dashboard {
      height: 268px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
      padding: 15px 0 0;
    }
    .headerholder {
      width: 95vw;
      display: flex;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;
      .liknos-logo {
        display: flex;
        align-items: center;
        width: 15%;
        max-width: 140px;
        min-width: 120px;
        img {
          width: 100%;
        }
      }
      .search-bar {
        width: 20%;
        .ant-input-lg {
          height: 40px;
          border: none;
          font-size: 1.4rem;
        }
        .ant-input-group::before {
          content: url('~assets/img/icons/search-header.svg');
          position: absolute;
          top: 0%;
          background: white;
          left: -20px;
          width: 30px;
          height: 40px;
          z-index: 999;
          border-radius: 4px 0 0 4px;
          text-align: center;
          vertical-align: middle;
          padding-top: 11px;
        }
        .ant-input-group-addon {
          width: 35%;
          background: var(--main-color);
          .ant-input-search-button {
            background: transparent;
            border: none;
            color: white !important;
            font-size: 1.2rem;
            i {
              vertical-align: middle;
              font-size: 1.1rem;
            }
          }
        }
      }
      .header-right-cont {
        display: flex;
        width: 65%;
        align-items: center;
        justify-content: flex-end;

        .icons {
          display: flex;
          justify-content: flex-end;
          color: white;
          align-items: center;
          
          &__shortcuts {
            font-size: 1.2rem;
            line-height: initial;
            cursor: pointer;
          }

          div {
            margin: 0 20px;

            img { width: 27px; }
          }

          .grid {
            margin-right: 5px;

            img {
              position: relative;
              top: -1px
            }
          }
        }
      }

      .lang-switcher {
        width: 58px;
        margin: 0 20px 0 10px;

        .el-input {
          &__inner { border-radius: 40px; }

          &__prefix {
            display: flex;
            align-items: center;
            margin-left: 5px;
          }
        }
      }


      .other-account-container{
        display: flex;
        justify-content: flex-end;
        margin-left: 10px;

        .avatar{
          img{ width: 26px; }
        }

        .name-other-account{
          font-family: GothamMedium;
          max-height: 36px;
          display: block;
          overflow: hidden;
          max-width: 100px;
        }
      }

      .account {
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        border: 2px solid #E9E9E9;
        border-radius: 30px;
        padding: 5px;
        transition: box-shadow .3s;

        &:hover { box-shadow: 0 0 11px rgba(121, 121, 121, 0.2); }

        .ant-dropdown-menu {
          border-radius: 14px;
          margin-top: 15px;
        }

        .ant-dropdown-menu-item {
          font-family: 'GothamMedium';
          color: black;
          a { color: black; }

          img {
            max-width: 20px;
            margin-right: 5px;
            opacity: 0.6;
          }
        }

        .avatar-dropdown { 
          display: flex;
          align-items: center;
        }
        
        .avatar-dot { margin-left: 5px; }

        .avatar-fullname {
          display: block;
          color: black;
          font-family: 'GothamBook';
        }
        .avatar {
          width: 45px;
          height: 45px;
          min-width: 45px;
          min-height: 45px;
          border-radius: 50%;
          background: #ededed;
          margin-right: 10px;
          position: relative;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &__user-company-name {
            color: var(--dark-text-color);
          }
        }
      }
    }
  }

  // normalize
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    background: transparent;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
  }
}
.shortcut-line{
  margin-bottom: 0.5rem;

  &__content {
    display: flex;
    justify-content: space-between;

    input {
      height: 32px;
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 1;
      border-radius: 8px;
      color: #000 !important;
    }
  }

}

.final-shortcut{
  width: auto;
  display: inline-block;
  color:#000;
  background-color: #dcdcdc;
  border-radius: 10px;
  padding: 5px 10px;
}

.ant-menu {
  margin-top: 7vh;
  li.ant-menu-item {
    padding-left: 0 !important;
    height: auto;
    a {
      padding: 5px 0;
      border-radius: 0 14px 14px 0px;
      &.is-active {
        background: white;
        color: #080522 !important;
        img {
          -webkit-filter: invert(100%) !important;
        }
      }
    }
    img {
      margin-left: 15px;
      margin-right: 5px;
    }
  }
  .ant-menu-submenu-title {
    height: auto !important;
    padding: 5px 0 !important;
    border-radius: 0 14px 14px 0px;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-menu-submenu-title {
  padding: 0 !important;
  .menu-title {
    padding-left: 5px;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .img-holder {
    margin: 0 5px 0px 15px;
  }
}
ul.ant-menu-sub {
  background: #080521 !important;
  border-radius: 0 !important;
  padding: 0 20px !important;
}
.ant-menu-submenu-vertical {
  li {
    position: relative;
  }
}
.ant-menu-submenu-inline {
  .menu-title {
    display: none;
    }
}
ul.ant-menu-submenu-content {
  li.ant-menu-item {
    position: relative;
  }
}
.clearance-modal {
  .ant-modal {
    width: 35vw !important;

    .ant-btn:nth-child(2) {
      background-color: #1890ff;
      color: white;
    }
  }
}


.gradient-top {
  background: linear-gradient(to bottom, #6BD3FF, #ffffff);
  height: 250px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 25px;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
  }
}


.ant-modal-content { border-radius: 20px; }

.financial-results {

  .error {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;

    span {
      font-weight: bold;
      font-size: 1.6rem;
      margin-left: 30px;
    }
  }

  &--items {
    width: 280px;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cecece;

    &_data { 
      margin-bottom: 10px; 
      
      img { width: 22px; }
    }
    
    &_title {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
}

.cancellation-fees {
  button.ant-btn.ant-btn-primary {
    color: white;
  }
}

@media (min-width: 1800px) {
  #components-layout-demo-custom-trigger {
  width:100%;
  }

  .clearance-modal {
    .ant-modal { width: 35vw !important; }
  }
}

@media (max-width: 1500px) {  
  .clearance-modal {
    .ant-modal { width: 40vw !important; }
  }
}

@media (max-width: 1200px) {
  .clearance-modal {
    .ant-modal { width: 45vw !important; }
  }
}

@media (max-width: 900px) {
  .clearance-modal {
    .ant-modal { width: 60vw !important; }
  }
}

@media (max-width: 900px) {
  #components-layout-demo-custom-trigger { min-height: 400vh; }

  .clearance-modal {
    .ant-modal { width: 60vw !important; }
  }

  .burger{
    position: fixed;
    left: 13px;
  }
}
</style>
